<template>
  <v-card class="mt-2 ml-2 mr-2">
    <FExpedisiTable ref="refTabExpedisiClick"/>
  </v-card>
</template>

<script>
import FExpedisiTable from "../../components/mitra/expedisi/FExpedisiTable";

export default {
  components: { FExpedisiTable },
  data() {
    return {
      firstTabTitle: 'EXPEDISI',
      firstTabIcon: 'mdi-factory'
    }
  },
  methods: {
    tabExpedisiClick(){
      try {
        this.$refs.refTabExpedisiClick.fetchParent()
      }catch (e) {
        e.toString()
      }

    },

  }
}
</script>

<style scoped>
</style>