export default class FExpedisi {
  constructor(
      id,
      fdivisionBean,
      kode1,
      kode2,
      description,
      avatarImage,
      childOf,
      address1,
      district1,
      city1,
      state1,
      phone,
      countryCode,
      statusActive,
      zipCode,
      email,
      codPersen,
      created,
      modified,
      modifiedBy
  ) {
    this.id = id;
    this.fdivisionBean = fdivisionBean;
    this.kode1 = kode1;
    this.kode2 = kode2;
    this.description = description;
    this.avatarImage = avatarImage;
    this.childOf = childOf;
    this.address1 = address1;
    this.district1 = district1;
    this.city1 = city1;
    this.state1 = state1;
    this.phone = phone;
    this.countryCode = countryCode;
    this.statusActive = statusActive;
    this.zipCode = zipCode;
    this.email = email;
    this.codPersen = codPersen;
    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }
}